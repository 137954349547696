// extracted by mini-css-extract-plugin
export var mainWrapper = "maine_digest_archives-module--mainWrapper--9PfWw";
export var heroImage = "maine_digest_archives-module--heroImage--WzxPi";
export var columnWrapper = "maine_digest_archives-module--columnWrapper--qsdxe";
export var headingLogo = "maine_digest_archives-module--headingLogo--h8tKb";
export var headingWrapper = "maine_digest_archives-module--headingWrapper--uBexn";
export var heading = "maine_digest_archives-module--heading--0x2i4";
export var instance = "maine_digest_archives-module--instance--FnBEN";
export var subheading = "maine_digest_archives-module--subheading--mdi5e";
export var textWrapper = "maine_digest_archives-module--textWrapper--V09zJ";
export var latestnewsarticleheadline = "maine_digest_archives-module--latestnewsarticleheadline--z5fF6";
export var dateline = "maine_digest_archives-module--dateline--tKij8";
export var newsArchives = "maine_digest_archives-module--newsArchives--c-JNa";
export var link = "maine_digest_archives-module--link--QZyS5";
export var photosWrapper = "maine_digest_archives-module--photosWrapper--2kxJ+";
export var photo = "maine_digest_archives-module--photo--Ndl0Y";
export var photoCaption = "maine_digest_archives-module--photoCaption--dHdMH";
export var rightPhotoCaption = "maine_digest_archives-module--rightPhotoCaption--ESbJx";
export var rightPhoto = "maine_digest_archives-module--rightPhoto--six7u";
export var rightCaptions = "maine_digest_archives-module--rightCaptions--R3qcE";
export var afterCaption = "maine_digest_archives-module--afterCaption--TtWiD";